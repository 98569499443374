var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "row"
  }, [_vm.loading ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row mt-5 pt-5"
  }, [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "col-md-12"
  })])]) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "col-md-12"
  }, [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.tableData,
      "header-cell-style": {
        background: 'rgba(212, 216, 226, 0.2)'
      },
      "empty-text": 'No Data Available',
      "height": "60vh"
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Message",
      "prop": "message",
      "width": ""
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Status",
      "prop": "status",
      "width": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.status == 2 ? _c('span', {
          staticStyle: {
            "color": "#36D962"
          }
        }, [_vm._v("Birthday")]) : _vm._e(), scope.row.status == 3 ? _c('span', {
          staticStyle: {
            "color": "#f84d4d"
          }
        }, [_vm._v("Anniversary")]) : _vm._e()];
      }
    }], null, false, 2479532190)
  }), _c('el-table-column', {
    attrs: {
      "label": "Action",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c('button', {
          staticClass: "text-danger",
          on: {
            "click": function ($event) {
              return _vm.deleteNotification(scope);
            }
          }
        }, [_vm._v(" Delete ")])];
      }
    }], null, false, 2249835480)
  })], 1)], 1) : _vm._e(), _c('ActionModal', {
    ref: "actionModal",
    attrs: {
      "payload": _vm.payload
    },
    on: {
      "close": _vm.closedModal
    }
  }), _c('NotificationsModal', {
    ref: "notificationModal",
    attrs: {
      "payload": _vm.payload
    },
    on: {
      "close": _vm.closedModal
    }
  }), _c('ActionModal', {
    ref: "unpublishModal",
    attrs: {
      "payload": _vm.payload
    },
    on: {
      "close": _vm.closedModal
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };