import { render, staticRenderFns } from "./birthdaysAndAnniversaries.vue?vue&type=template&id=70c594ec&scoped=true"
import script from "./birthdaysAndAnniversaries.vue?vue&type=script&lang=js"
export * from "./birthdaysAndAnniversaries.vue?vue&type=script&lang=js"
import style0 from "./birthdaysAndAnniversaries.vue?vue&type=style&index=0&id=70c594ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c594ec",
  null
  
)

export default component.exports